import React from 'react'

export default function Home() {
  return (
    <div>
        <section className="filmoja-slider-area fix">
  <div className="filmoja-slide owl-carousel owl-loaded owl-drag">
    <div className="owl-stage-outer">
      <div
        className="owl-stage"
        style={{
          transform: "translate3d(-2526px, 0px, 0px)",
          transition: "all 0s ease 0s",
          width: 8844
        }}
      >
        <div className="owl-item cloned" style={{ width: "1263.33px" }}>
          <div className="filmoja-main-slide slide-item-5">
            <div className="filmoja-main-caption">
              <div className="filmoja-caption-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="slider-content-holder">
                        <div className="slider-text">
                          <h4>Theater</h4>
                          <h2>
                           Pushkar RangManch
                          </h2>
                          <p className="release-slider">
                      
                          </p>
                        </div>
                        <div className="slider-play">
                          <a
                            className="popup-youtube"
                            href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                          >
                           <i class="fa-solid fa-play"></i> watch upcoming 
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-item cloned" style={{ width: "1263.33px" }}>
          <div className="filmoja-main-slide slide-item-3">
            <div className="filmoja-main-caption">
              <div className="filmoja-caption-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="slider-content-holder">
                        <div className="slider-text">
                        <h4>Theater</h4>
                          <h2>
                           Pushkar RangManch
                          </h2>
                          <p className="release-slider">
                      
                          </p>
                        </div>
                        <div className="slider-play">
                          <a
                            className="popup-youtube"
                            href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                          >
                           <i class="fa-solid fa-play"></i> watch upcoming 
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-item active" style={{ width: "1263.33px" }}>
          <div className="filmoja-main-slide slide-item-2">
            <div className="filmoja-main-caption">
              <div className="filmoja-caption-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="slider-content-holder">
                        <div className="slider-text">
                        <h4>Theater</h4>
                          <h2>
                           Pushkar RangManch
                          </h2>
                          <p className="release-slider">
                      
                          </p>
                        </div>
                        <div className="slider-play">
                          <a
                            className="popup-youtube"
                            href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                          >
                           <i class="fa-solid fa-play"></i> watch upcoming 
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-item" style={{ width: "1263.33px" }}>
          <div className="filmoja-main-slide slide-item-5">
            <div className="filmoja-main-caption">
              <div className="filmoja-caption-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="slider-content-holder">
                        <div className="slider-text">
                        <h4>Theater</h4>
                          <h2>
                           Pushkar RangManch
                          </h2>
                          <p className="release-slider">
                      
                          </p>
                        </div>
                        <div className="slider-play">
                          <a
                            className="popup-youtube"
                            href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                          >
                           <i class="fa-solid fa-play"></i> watch upcoming 
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-item" style={{ width: "1263.33px" }}>
          <div className="filmoja-main-slide slide-item-3">
            <div className="filmoja-main-caption">
              <div className="filmoja-caption-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="slider-content-holder">
                        <div className="slider-text">
                        <h4>Theater</h4>
                          <h2>
                           Pushkar RangManch
                          </h2>
                          <p className="release-slider">
                      
                          </p>
                        </div>
                        <div className="slider-play">
                          <a
                            className="popup-youtube"
                            href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                          >
                           <i class="fa-solid fa-play"></i> watch upcoming 
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-item cloned" style={{ width: "1263.33px" }}>
          <div className="filmoja-main-slide slide-item-2">
            <div className="filmoja-main-caption">
              <div className="filmoja-caption-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="slider-content-holder">
                        <div className="slider-text">
                        <h4>Theater</h4>
                          <h2>
                           Pushkar RangManch
                          </h2>
                          <p className="release-slider">
                      
                          </p>
                        </div>
                        <div className="slider-play">
                          <a
                            className="popup-youtube"
                            href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                          >
                           <i class="fa-solid fa-play"></i> watch upcoming 
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-item cloned" style={{ width: "1263.33px" }}>
          <div className="filmoja-main-slide slide-item-5">
            <div className="filmoja-main-caption">
              <div className="filmoja-caption-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="slider-content-holder">
                        <div className="slider-text">
                        <h4>Theater</h4>
                          <h2>
                           Pushkar RangManch
                          </h2>
                          <p className="release-slider">
                      
                          </p>
                        </div>
                        <div className="slider-play">
                          <a
                            className="popup-youtube"
                            href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                          >
                           <i class="fa-solid fa-play"></i> watch upcoming 
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="owl-nav">
      <button type="button" role="presentation" className="owl-prev">
        <img src="./prev-1.png" />
      </button>
      <button type="button" role="presentation" className="owl-next">
        <img src="./next-1.png" />
      </button>
    </div>
    <div className="owl-dots disabled" />
  </div>
</section>
<section className="filmoja-top-movies-area section_70">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="filmoja-heading">
          <h2>
            Shows :<span> 2024</span>
          </h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="top-movie-slider owl-carousel owl-loaded owl-drag">
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(-1130px, 0px, 0px)",
                transition: "all 0s ease 0s",
                width: 3390
              }}
            >
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-1.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        Dareing Joker
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-2.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        Furious race
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-3.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        the walk
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-4.jpg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        Blood Day war
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-5.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        mr.cartoon
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-1.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        Dareing Joker
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-2.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        Furious race
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-3.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        the walk
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-4.jpg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        Blood Day war
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-5.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        mr.cartoon
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-1.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        Dareing Joker
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-2.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        Furious race
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-3.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        the walk
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-4.jpg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        Blood Day war
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: 206, marginRight: 20 }}
              >
                <div className="single-top-movie">
                  <div className="top-movie-wrap">
                    <div className="top-movie-img">
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        <img src="./top-slider-5.jpeg" alt="top movies" />
                      </a>
                    </div>
                    <div className="thumb-hover">
                      <a
                        className="play-video"
                        href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
                      >
                       <i class="fa-solid fa-play"></i>
                      </a>
                      <div className="thumb-date">
                        <span>05:30</span>
                      </div>
                    </div>
                  </div>
                  <div className="top-movie-details">
                    <h4>
                      <a href="http://themescare.com/demos/filmoja-view/index.html#">
                        mr.cartoon
                      </a>
                    </h4>
                    <div className="movie-details-thumbs">
                      <ul>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-thumbs-up" />
                            256
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <i className="fa fa-eye" />
                            45
                          </a>
                        </li>
                        <li>
                          <a href="http://themescare.com/demos/filmoja-view/index.html#">
                            <span className="rating">
                              <i className="fa fa-star" />
                              4.5
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="owl-nav disabled">
            <button type="button" role="presentation" className="owl-prev">
              <i className="fa fa-long-arrow-left" />
            </button>
            <button type="button" role="presentation" className="owl-next">
              <i className="fa fa-long-arrow-right" />
            </button>
          </div>
          <div className="owl-dots disabled" />
        </div>
      </div>
    </div>
  </div>
</section>
<section className="filmoja-theater-area section_70">
  <div className="container">
    <div className="row">
      <div className="col-md-5">
        <div className="theater-left">
          <div className="theater-box">
            <div className="theater-text">
              <h3>Upcoming in theater</h3>
              <h4>Action, Adventure, Fantasy</h4>
              <p>researved your seat,before you miss tomorrow.</p>
              <a
                href="http://themescare.com/demos/filmoja-view/index.html#"
                className="filmoja-btn"
              >
                discover all
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-7">
        <div className="theater-slider slider-for">
          <div className="single-theater">
            <img src="./theater.jpeg" alt="theater thumb" />
            <a
              className="play-video"
              href="https://www.youtube.com/watch?v=1Q8fG0TtVAY"
            >
             <i class="fa-solid fa-play"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="filmoja-news-area section_70">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="filmoja-heading">
          <h2>
            latest <span>news</span>
          </h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <div className="single-news-item">
          <div className="news-image">
            <a href="http://themescare.com/demos/filmoja-view/index.html#">
              <img src="./big_news.jpg" alt="news" />
            </a>
            <div className="news-date">
              <span className="date">12</span>
              <span className="month">nov</span>
            </div>
          </div>
          <div className="news-text">
            <h3>
              <a href="http://themescare.com/demos/filmoja-view/index.html#">
                Win a Wizarding World holiday with Fantastic
              </a>
            </h3>
            <div className="post-meta">
              <p>
                <a href="http://themescare.com/demos/filmoja-view/index.html#">
                  <i className="fa fa-user" />
                  David Cassidy
                </a>
              </p>
              <p>
                <a href="http://themescare.com/demos/filmoja-view/index.html#">
                  <i className="fa fa-tags" />
                  Cycling
                </a>
              </p>
            </div>
            <div className="post-content">
              <p>
                Eiusmod tempor incididunt ut labore et dolore magna
                aliqua.Eiusmod tempor incididunt ut labore et dolore magna
                aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="news-side-list">
          <div className="single-news-side">
            <div className="news-side-img">
              <a href="http://themescare.com/demos/filmoja-view/index.html#">
                <img src="./news-side-1.jpg" alt="side news" />
              </a>
              <div className="news-date">
                <span className="date">12</span>
                <span className="month">nov</span>
              </div>
            </div>
            <div className="news-side-text">
              <h4>
                <a href="http://themescare.com/demos/filmoja-view/index.html#">
                  Doctor Strange assembles with the Avengers
                </a>
              </h4>
              <div className="post-meta">
                <p>
                  <a href="http://themescare.com/demos/filmoja-view/index.html#">
                    <i className="fa fa-user" />
                    David Cassidy
                  </a>
                </p>
                <p>
                  <a href="http://themescare.com/demos/filmoja-view/index.html#">
                    <i className="fa fa-tags" />
                    Cycling
                  </a>
                </p>
              </div>
              <div className="post-content">
                <p>
                  Eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="news-side-list">
          <div className="single-news-side">
            <div className="news-side-img">
              <a href="http://themescare.com/demos/filmoja-view/index.html#">
                <img src="./news-side-2.jpg" alt="side news" />
              </a>
              <div className="news-date">
                <span className="date">12</span>
                <span className="month">nov</span>
              </div>
            </div>
            <div className="news-side-text">
              <h4>
                <a href="http://themescare.com/demos/filmoja-view/index.html#">
                  Doctor Strange assembles with the Avengers
                </a>
              </h4>
              <div className="post-meta">
                <p>
                  <a href="http://themescare.com/demos/filmoja-view/index.html#">
                    <i className="fa fa-user" />
                    David Cassidy
                  </a>
                </p>
                <p>
                  <a href="http://themescare.com/demos/filmoja-view/index.html#">
                    <i className="fa fa-tags" />
                    Cycling
                  </a>
                </p>
              </div>
              <div className="post-content">
                <p>
                  Eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
